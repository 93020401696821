body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wmde-markdown-var.w-md-editor.w-md-editor-show-live {
  width: 100% !important;
}

.RaDatagrid-headerCell span {
  font-weight: 600;
}

.form-item {
  margin: 15px 0;
  width: 100%;
}

.form-item label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  opacity: 0.7;
}

.w-md-editor-fullscreen {
  z-index: 1300 !important;
}

.error {
  font-weight: 500;
  color: red;
  font-size: 18px;
}

.error-image {
  font-weight: 500;
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.error-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.error-box svg {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
}

.MuiButtonBase-root.MuiTableSortLabel-root.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root span {
  font-weight: 700;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.RaMenuItemLink-active.css-krc73u-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root {
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.07);
}

.image-field--error p {
   color: red;
}

.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  flex-wrap: wrap;
}

.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
  position: static;
}

td .MuiTypography-root.MuiTypography-body2 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.add-filter {
  display: none !important;
}

.language-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}